import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const BrowserMessageCompWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
`;

const AlertContainer = styled.div`
  max-width: 36rem;
  margin: 2rem auto;
  background-color: #fff;
  border-radius: 0.75rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-left: 4px solid #f59e0b;

  @media (max-width: 640px) {
    margin: 1rem;
    padding: 1rem;
  }
`

const AlertContent = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 640px) {
    flex-direction: column;
  }
`

const IconContainer = styled.div`
  flex-shrink: 0;
  margin-right: 1rem;

  @media (max-width: 640px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`

const TextContainer = styled.div`
  flex-grow: 1;
`

const AlertTitle = styled.h2`
  color: #b45309;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;

  @media (max-width: 640px) {
    font-size: 1.125rem;
  }
`

const AlertDescription = styled.p`
  color: #78350f;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;

  @media (max-width: 640px) {
    font-size: 0.875rem;
  }
`

const BrowserList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
`

const BrowserItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.875rem;
  color: #92400e;

  @media (max-width: 480px) {
    flex-direction: row;
    align-items: center;
  }
`

const BrowserIcon = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  background-color: #fef3c7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;

  @media (max-width: 480px) {
    width: 2rem;
    height: 2rem;
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
`

const Button = styled.button`
  background-color: #f59e0b;
  color: #fff;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d97706;
  }

  &:focus {
    outline: 2px solid #f59e0b;
    outline-offset: 2px;
  }
`

const WarningIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#f59e0b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
    <line x1="12" y1="9" x2="12" y2="13"></line>
    <line x1="12" y1="17" x2="12.01" y2="17"></line>
  </svg>
)

const ChromeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#4285F4">
    <path d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0zm0 21.6c-5.304 0-9.6-4.296-9.6-9.6S6.696 2.4 12 2.4s9.6 4.296 9.6 9.6-4.296 9.6-9.6 9.6z" />
    <path d="M12 4.8c-3.972 0-7.2 3.228-7.2 7.2s3.228 7.2 7.2 7.2 7.2-3.228 7.2-7.2-3.228-7.2-7.2-7.2zm0 12c-2.648 0-4.8-2.152-4.8-4.8s2.152-4.8 4.8-4.8 4.8 2.152 4.8 4.8-2.152 4.8-4.8 4.8z" />
    <circle cx="12" cy="12" r="2.4" />
  </svg>
)

const FirefoxIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#FF9500">
    <path d="M21.634 11.158c-.002-.048-.004-.095-.008-.142-.037-.713-.226-1.39-.525-2.023-.484-1.023-1.029-1.741-1.867-2.457-.246-.21-.504-.397-.78-.55-.34-.187-.71-.317-1.075-.403-.442-.105-.89-.16-1.34-.17-.11-.002-.22-.002-.33.002-.524.02-1.04.11-1.54.27-.59.19-1.13.47-1.62.83-.27.2-.52.42-.75.66-.19.2-.37.41-.53.63-.31.43-.56.9-.75 1.4-.11.29-.2.59-.26.89-.05.24-.08.48-.1.72-.01.15-.02.3-.02.46 0 .05.01.1.01.15.02.51.11 1.01.27 1.49.14.42.33.82.57 1.19.23.36.5.69.81.98.3.28.63.52.99.72.35.19.72.34 1.1.45.37.11.75.18 1.14.21.38.03.77.03 1.15-.01.37-.04.74-.11 1.1-.22.35-.1.69-.24 1.01-.41.31-.16.6-.35.87-.57.26-.21.5-.45.71-.71.21-.25.4-.53.56-.82.15-.28.28-.57.38-.87.09-.28.16-.57.21-.86.04-.24.06-.48.07-.72 0-.1 0-.19-.01-.29zm-9.364 5.259c-.83-.42-1.47-1.05-1.89-1.88-.17-.34-.3-.7-.38-1.08-.06-.29-.09-.58-.09-.88-.01-.39.03-.77.12-1.15.1-.43.26-.84.48-1.22.24-.41.54-.78.89-1.1.34-.31.73-.57 1.15-.76.41-.18.84-.3 1.29-.35.41-.05.82-.04 1.22.02.38.06.75.16 1.1.32.33.14.64.32.92.54.27.21.52.45.73.72.21.26.39.55.53.86.15.32.26.65.33 1 .07.34.1.69.09 1.04-.01.35-.06.7-.15 1.04-.1.37-.24.72-.42 1.05-.19.35-.42.67-.69.95-.27.29-.57.54-.91.75-.33.2-.68.36-1.05.48-.36.12-.73.2-1.11.23-.37.03-.74.02-1.11-.03-.35-.05-.7-.13-1.03-.26-.31-.12-.61-.27-.89-.45zm9.552-5.097c-.06.38-.16.75-.31 1.1-.17.41-.4.79-.68 1.13-.28.34-.6.64-.96.89-.35.25-.73.45-1.13.6-.39.15-.8.25-1.21.31-.4.06-.81.08-1.22.06-.39-.02-.78-.07-1.16-.16-.37-.09-.73-.21-1.08-.37-.33-.15-.65-.33-.94-.55-.29-.21-.55-.45-.78-.72-.23-.26-.43-.55-.6-.86-.17-.3-.31-.62-.41-.95-.11-.34-.19-.69-.23-1.05-.04-.35-.05-.7-.02-1.05.03-.35.09-.69.18-1.03.09-.33.21-.65.36-.95.15-.3.32-.58.52-.84.2-.26.42-.5.67-.71.24-.21.51-.4.79-.56.28-.16.58-.29.89-.39.3-.1.62-.17.93-.22.31-.04.62-.06.93-.05.31.01.62.05.92.11.3.06.6.15.88.26.28.11.55.24.8.4.25.15.48.33.69.52.21.2.4.41.57.64.17.23.32.47.45.73.13.25.24.52.32.79.08.27.14.55.18.83.03.28.05.56.04.84 0 .28-.03.56-.08.84z" />
  </svg>
)

const SafariIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#006CFF">
    <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10z" />
    <path d="M16.414 7.586l-5.656 5.656-2.828-2.828 5.656-5.656 2.828 2.828z" />
    <path d="M10.758 13.242l5.656-5.656 2.828 2.828-5.656 5.656-2.828-2.828z" />
  </svg>
)

export default function BrowserMessageComp() {
  const openDefaultBrowser = () => {
    const win = window.location;
    window.open(win.href, '_system')
  }

  return (
    <BrowserMessageCompWrapper>
      <AlertContainer>
        <AlertContent>
          <IconContainer>
            <WarningIcon />
          </IconContainer>
          <TextContainer>
            <AlertTitle>Browser Compatibility Notice</AlertTitle>
            <AlertDescription>
              For the best uninterrupted ordering experience, we recommend using an up-to-date version of one of the following browsers:
            </AlertDescription>
            <BrowserList>
              <BrowserItem>
                <BrowserIcon>
                  <ChromeIcon />
                </BrowserIcon>
                Chrome
              </BrowserItem>
              <BrowserItem>
                <BrowserIcon>
                  <FirefoxIcon />
                </BrowserIcon>
                Firefox
              </BrowserItem>
              <BrowserItem>
                <BrowserIcon>
                  <SafariIcon />
                </BrowserIcon>
                Safari
              </BrowserItem>
            </BrowserList>
            <Button onClick={openDefaultBrowser}>
              Open in Default Browser
            </Button>
          </TextContainer>
        </AlertContent>
      </AlertContainer>
    </BrowserMessageCompWrapper>
  );
}
