import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { decrementQty, incrementQty } from "../../store/selectItem";
import { NewAddIcon, NewSubIcon } from "../../Utils/Icons";
import { handleDineInCondition } from "../../Utils/CommanFunctions";
import { useLocation } from "react-router-dom";

function AddQuantity({ direction, colorStyle }) {
  const { quantity, item_price } = useSelector(
    (state) => state.selectItemSlice
  );
  const dispatch = useDispatch();
  const customTheme = useSelector((state) => state?.theme?.branding);
  const RestuarantDetails = useSelector(
    (state) => state?.restaurentSlice?.restaurentData
  );
  const restuarantProfileInfo = useSelector(
    (state) => state?.restaurentSlice?.restuarentProfileInfo
  );
  const location = useLocation();

  const decrement = () => {
    dispatch(decrementQty());
  };

  const increment = () => {
    dispatch(incrementQty());
  };

  console.log(item_price, "item_priceitem_priceitem_priceitem_price");

  return (
    <QuantitiyWrapperParent
      dir={direction}
      style={{ background: customTheme?.background_color }}
    >
      <div className="QuantitiyWrapper">
        <div className="QunatityContainer">
          {/* <i className="dolloarSym">
            <QuantityIcon color={customTheme?.theme_color} />
          </i> */}

          <h1
            style={{ color: customTheme?.theme_color }}
            className="QuantityHeading"
          >
            {/* <IntlMessage id="AddItemDrawer.quantity" /> */}
            {restuarantProfileInfo?.currency || "QAR"} {item_price}
          </h1>
        </div>
        {handleDineInCondition(RestuarantDetails, location) && (
          <div
            className="QuantityMainWrapper"
            style={{ background: customTheme?.background_color }}
            // style={{ border: `1px solid ${customTheme?.theme_color}` }}
          >
            <section
              className="decrementButton"
              style={{
                color: customTheme?.theme_color,
                border: `2px solid ${customTheme?.theme_color}`,
                marginBottom: "1px",
              }}
              onClick={decrement}
            >
              <NewSubIcon color={customTheme?.theme_color} />
            </section>
            <p>{quantity}</p>
            <section
              className="incrementButton"
              onClick={increment}
              style={{
                border: `2px solid ${customTheme?.theme_color}`,
                background: customTheme?.theme_color,
              }}
            >
              <NewAddIcon />
            </section>
          </div>
        )}
      </div>
    </QuantitiyWrapperParent>
  );
}

export default AddQuantity;

const QuantitiyWrapperParent = styled.div`
  background: white;
  padding-bottom: 10px;

  .QuantitiyWrapper {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    // margin-top: 15px;

    ${
      "" /* p {
      margin: 1px 10px;
      font-family: "Jost";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: #f55a2c;
      margin-left: 10px;
    } */
    }
  }
  .FreeItemName {
    padding-left: 29px;
    span {
      display: flex;
      font-family: Poppins;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;

      span {
        margin-top: 1.6px;
      }
    }
    p {
      margin: 0;
      color: #7b7f91 !important;
      margin-right: 5px;
    }
  }
  .QunatityContainer {
    display: flex;
    align-items: center;
    padding-top: 3px;
    gap: 7px;
    p {
      font-family: "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #fc2b6e;
    }
    .QuantityHeading {
      font-family: "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 26px;
      color: #fc2b6e;
    }
  }

  .QuantityMainWrapper button:hover {
    cursor: pointer;
  }
  .QuantityMainWrapper {
    display: flex;
    width: fit-content;
    height: 36px;
    justify-content: space-evenly;
    background: #ffffff;
    border: none;
    gap: 11px;

    .decrementButton {
      width: 36px;
      height: 36px;
      border: none;
      background: none;
      color: #fc2b6e;
      font-size: 25px;
      border: 2px solid #fc2b6e;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .incrementButton {
      width: 36px;
      height: 36px;
      border: none;
      background: #fc2b6e;
      color: #fff;
      font-size: 22px;
      border: 2px solid #fc2b6e;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      color: #202020;
      margin: 5px;
      margin-top: 6.7px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .FreeItemContainer {
    display: inline-grid;
    p {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #fc2b6e;
    }
    .QuantityHeading {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;

      color: #fc2b6e;
      ${"" /* margin-top: 0; */}
    }
  }
  .freeItemHeader {
    display: flex;
    margin-bottom: -11px;
  }
  .ArrowIcon {
    div {
      margin-top: 20px;
    }
  }
  .Special-Note {
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 10px;
    width: 90%;
    margin: auto;

    textarea {
      width: 82vw;
      background: #f5f5f5;
      border: none;
      height: 80px;
      border-radius: 10px;
      box-shadow: 20px;
      padding-top: 10px;
      padding-left: 25px;
      opacity: 1 !important;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #202020;
      :focus {
        outline: none;
      }
    }
  }
`;
