import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { SelectAddOn } from "../../store/selectItem";
import IntlMessage from "../../Utils/IntlMessage";
import { AddOnIcon } from "../../Utils/Icons";

function AddOn({ options, colorStyle, addOn }) {
  const dispatch = useDispatch();
  const dir = useSelector((state) => state.selectLang.direction);
  const POSisEnable = useSelector((state) => state?.posSlice?.isEnable);
  const customTheme = useSelector((state) => state?.theme?.branding);
  const restuarantProfileInfo = useSelector(
    (state) => state?.restaurentSlice?.restuarentProfileInfo
  );

  console.log(options, "optionnnnnnnnnnnnnn");

  const handleAddOn = (id, price, groupId, calories, posModifierPrice) => {
    if (POSisEnable) {
      const obj = {
        id,
        posModifierPrice,
        groupId,
        calories,
      };
      dispatch(SelectAddOn(obj));
    } else {
      const obj = {
        id,
        price,
        groupId,
        calories,
      };
      console.log(obj, "handleAddOn");
      dispatch(SelectAddOn(obj));
    }
  };

  return (
    <AddOnSection
      dir={dir}
      style={{ background: customTheme?.background_color }}
    >
      <div className="Innersection">
        <div className="InnermenuHead">
          <div className="PriceHeader">
            {/* <i className="dolloarSym" >
              <AddOnIcon color={customTheme?.theme_color} />
            </i> */}
            <h1
              style={{ color: customTheme?.theme_color }}
              className="PriceHeading"
            >
              <IntlMessage id="AddItemDrawer.addon" />
            </h1>
          </div>
        </div>

        {options?.map((item, key) => {
          return (
            <div>
              <div key={key} className="groupDetails">
                <h1>
                  <b style={{ color: customTheme?.header_color }}>
                    {" "}
                    {dir === "ltr"
                      ? item?.OptionGroupModule?.name
                      : item?.OptionGroupModule?.ar_name}
                  </b>
                </h1>
              </div>
              {item?.OptionGroupModule?.OptionItemModules?.map(
                (option, key) => {
                  return (
                    <div key={key} className="Innermenu">
                      <>
                        <ChekboxContainer themeColor={customTheme?.theme_color}>
                          <PricesWrapper key={key + 3} dir={dir}>
                            <p
                              key={key + 4}
                              style={{ color: customTheme?.header_color }}
                            >
                              {dir === "ltr" ? option.name : option.ar_name}
                            </p>
                          </PricesWrapper>
                        </ChekboxContainer>

                        <PriceNumberContainer
                          key={key + 6}
                          themeColor={customTheme?.theme_color}
                        >
                          {POSisEnable ? (
                            <span
                              key={key + 7}
                              style={{ color: customTheme?.text_color }}
                            >
                              {restuarantProfileInfo?.currency || "QAR"}{" "}
                              {option.pos_option_item_price}
                            </span>
                          ) : (
                            <span
                              key={key + 7}
                              style={{ color: customTheme?.text_color }}
                            >
                              {restuarantProfileInfo?.currency || "QAR"}{" "}
                              {option.price}
                            </span>
                          )}

                          <div className="round">
                            <input
                              key={key + 2}
                              style={{ accentColor: customTheme?.theme_color }}
                              className="PriceListCheckbox"
                              id={`checkbox${option?.id}`}
                              type="checkbox"
                              name="topping"
                              value={option?.id}
                              onChange={(e) =>
                                handleAddOn(
                                  option?.id,
                                  option?.price,
                                  item?.OptionGroupModule?.id,
                                  option?.calories,
                                  option?.pos_option_item_price
                                )
                              }
                            />
                            <label for={`checkbox${option?.id}`}></label>
                          </div>
                        </PriceNumberContainer>
                      </>
                    </div>
                  );
                }
              )}
            </div>
          );
        })}
      </div>
    </AddOnSection>
  );
}

export default AddOn;

const CustomCheckBox = styled.div`
  width: 25px;
  height: 25px;
  background: red;
  border-radius: 50%;
`;

const Pricedetails = styled.div`
  margin-left: ${({ dir }) => dir === "ltr" && "20px"};
  margin-right: ${({ dir }) => dir === "rtl" && "20px"};

  p {
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #ffffff;
  }

  span {
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.6);
  }
`;

const AddOnSection = styled.div`
  background-color: white;
  padding: 6px 0px;

  .Innersection {
    width: 95%;
    margin: 0 auto;
  }

  .PriceHeader {
    display: flex;
  }

  .PriceHeading {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;

    color: #fc2b6e;
  }
  .Innermenu {
    width: 100%;
    padding: 0 15px;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #9797;
    border-radius: 4px;
    margin: 2px 0;

    &:last-child {
      border: none;
    }
  }
  .InnermenuHead {
    width: 100%;
    padding: 0;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .InnerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
const ChekboxContainer = styled.div`
  display: flex;
  position: relative;
  gap: 10px;
  align-items: center;

  .PriceListCheckbox {
    margin-bottom: 17px;
    accent-color: #fc2b6e;
  }
`;
const PricesWrapper = styled.div`
  p {
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: auto;
    letter-spacing: 0.02em;
    margin: 0;
    color: #000;
    text-transform: capitalize;
  }

  span {
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.02em;

    color: #000;
  }
`;

const PriceNumberContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  .round {
    position: relative;
    margin-top: 13px;
  }

  .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;

    left: 0;
    position: absolute;
    top: 0;
    width: 25px;
    height: 25px;
  }

  .round label:after {
    border: 3px solid #fff;
    content: "";
    opacity: 0;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  .round input[type="checkbox"] {
    visibility: hidden;
  }

  .round input[type="checkbox"]:checked + label {
    background-color: ${({ themeColor }) => themeColor};
    border-color: ${({ themeColor }) => themeColor};
  }

  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }

  span {
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #202020;

    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: auto;
    letter-spacing: 0.02em;
    margin: 0;
    color: #000;
    text-transform: capitalize;
  }
`;
